import { gql } from "graphql-tag";

export const DIMENSION_FIELDS = gql`
	fragment DimensionFields on Dimension {
		id
		name
		path
		measurement
		created_at
	}
`;

export const PAGINATION = gql`
	fragment Pagination on DimensionPagination {
		total
		per_page
		current_page
		from
		to
		last_page
		has_more_pages
	}
`;

export default { DIMENSION_FIELDS, PAGINATION };
