
import { defineComponent, ref, watchEffect, toRefs } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { Input, Select } from '@/components/input-elements';
import { GET_PRICE_GROUP } from '@/modules/customer/price-groups/graphql/Queries';
import { useI18n } from 'vue-i18n';
import Table from '../../../../../components/Table/Table.vue';

export default defineComponent({
    props: {
        priceGroupsList: Object
    },
    components: {
        Input,
        Select,
        Table
    },
    setup(props, context) {
        const i18n = useI18n();
        const { priceGroupsList } = toRefs(props);
        const priceGroups = ref([]) as Record<any, any>;
        const priceGroup = ref(null);
        const specialPrice = ref(null);
        const groupsList: any = ref([]);
        const priceGroupId = ref();
        const priceGroupBtn = ref(false);
        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.PRICE',
                key: 'price'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        watchEffect(() => {
            priceGroupsList?.value != undefined ? (groupsList.value = priceGroupsList?.value) : null;
        });

        const getPriceGroups = () => {
            Apollo.watchQuery({
                query: GET_PRICE_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                errorPolicy: 'all'
            }).subscribe(({ data }) => {
                priceGroups.value = [];
                data.price_groups.data.forEach(element => {
                    priceGroups.value.push({
                        label: element.name,
                        value: element.id
                    });
                });
            });
        };

        getPriceGroups();

        const addPriceGroup = () => {
            if (!specialPrice.value || !priceGroup.value) {
                Notify.error(i18n.t('message.FILL_ALL_MISSING_FIELDS'));
            } else {
                const groupName = priceGroups.value.find(ele => ele.value == priceGroup.value);
                if (priceGroupId.value != undefined) {
                    groupsList.value[priceGroupId.value].price = specialPrice.value;
                    groupsList.value[priceGroupId.value].value = priceGroup.value;
                    groupsList.value[priceGroupId.value].name = groupName.label;
                } else {
                    groupsList.value.push({
                        name: groupName.label,
                        value: priceGroup.value,
                        price: specialPrice.value
                    });
                }
                specialPrice.value = null;
                priceGroup.value = null;
                priceGroupId.value = undefined;
                context.emit('priceGroupsData', groupsList.value);
            }
        };

        const handleEdit = (index: number) => {
            specialPrice.value = groupsList.value[index].price;
            priceGroup.value = groupsList.value[index].value;
            priceGroupId.value = index;
        };

        const changePriceGroup = (id: number) => {
            const group = groupsList.value.find(ele => ele.value == id);
            if (group != undefined) {
                if (priceGroupId.value != undefined) {
                    if (groupsList.value[priceGroupId.value].value == id) {
                        priceGroupBtn.value = false;
                        return;
                    }
                }
                priceGroupBtn.value = true;
                Notify.error(i18n.t('message.PRICE_GROUP_ALREADY_EXISTS'));
            } else {
                priceGroupBtn.value = false;
            }
        };

        const handleDelete = (id: number) => {
            groupsList.value.splice(id, 1);
            context.emit('priceGroupsData', groupsList.value);
        };

        return {
            priceGroups,
            priceGroup,
            specialPrice,
            priceGroupId,
            priceGroupBtn,
            groupsList,
            columns,
            handleEdit,
            handleDelete,
            changePriceGroup,
            addPriceGroup
        };
    }
});
