import { gql } from "graphql-tag";
import { CATEGORY_FIELDS, CATEGORY_FIELDS_IS_PARENT, PAGINATION, CATEGORY_FIELDS_DROPDOWN } from "./Fragments";

export const GET_CATEGORIES = gql`
    query GetCategories($page: Int, $limit: Int) {
        categories(limit:$limit, page:$page){
            ...Pagination
            data {
                ...CategoryFields
            }
        }
    }
    ${CATEGORY_FIELDS},
    ${PAGINATION},
`;

export const GET_CATEGORIES_DROPDOWN_LIST = gql`
    query GetCategories($page: Int, $limit: Int) {
        categories(limit:$limit, page:$page){
            ...Pagination
            data {
                ...CategoryFields
            }
        }
    }
    ${CATEGORY_FIELDS_DROPDOWN},
    ${PAGINATION},
`;

export const SEARCH_CATEGORIES = gql`
    query SearchCategories($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String , $type:String , $date_from:String , $date_to:String) {
        search_categories(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale , type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...CategoryFields
            }
        }
    }
    ${CATEGORY_FIELDS},
    ${PAGINATION},
`;


export const IS_PARENT_CATEGORIES = gql`
   query GetCategoriesIsParent($page: Int, $limit: Int) {
        categories(limit:$limit, page:$page) {
            data {
               ...CategoryFields
            }
        }
    }
    ${CATEGORY_FIELDS_IS_PARENT},
`;

export default { GET_CATEGORIES, IS_PARENT_CATEGORIES, SEARCH_CATEGORIES, GET_CATEGORIES_DROPDOWN_LIST };
