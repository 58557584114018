import { gql } from "graphql-tag";

export const CATEGORY_FIELDS = gql`
	fragment CategoryFields on Category {
		id
		name
		status
		parent_id
		description
		meta_description
		meta_title
		position
		path
		parent {
			id
			name
		}
		category_to_site {
			id
		}
		image {
			id
			file_path
		}
	}
`;

export const CATEGORY_FIELDS_DROPDOWN = gql`
	fragment CategoryFields on Category {
		id
		name
	}
`;

export const CATEGORY_FIELDS_IS_PARENT = gql`
	fragment CategoryFields on Category {
		id
		name
		status
		parent_id
		children {
			id
			name
			children {
				id
				name
			}
		}
	}
`;

export const PAGINATION = gql`
	fragment Pagination on CategoryPagination {
		total
		per_page
		current_page
		from
		to
		last_page
		has_more_pages
	}
`;

export default { CATEGORY_FIELDS, PAGINATION, CATEGORY_FIELDS_IS_PARENT, CATEGORY_FIELDS_DROPDOWN };
