import { gql } from "graphql-tag";

export const ATTRIBUTE_FIELDS = gql`
  fragment AttributeFields on Attribute {
    id
    name
    path
    created_at
  }
`;

export const PAGINATION = gql`
  fragment Pagination on AttributePagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { ATTRIBUTE_FIELDS, PAGINATION };