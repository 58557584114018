import { gql } from 'graphql-tag';

export const PRICE_GROUP_FIELDS = gql`
    fragment PriceGroupFields on PriceGroup {
        id
        uuid
        name
        path
        created_at
    }
`;

export const PAGINATION = gql`
    fragment Pagination on PriceGroupPagination {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
`;

export default { PRICE_GROUP_FIELDS, PAGINATION };
