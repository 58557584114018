import { gql } from "graphql-tag";
import { TAX_RULE_FIELDS, PAGINATION } from "./Fragments";

export const GET_TAX_RULE = gql`
    query GetTaxRule($page: Int, $limit: Int,$id: Int) {
        tax_rules(limit:$limit, page:$page,id:$id){
            ...Pagination
            data {
                ...TaxRuleFields
            }
        }
    }
    ${TAX_RULE_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_TAX_RULE = gql`
    query SearchTaxRule($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_tax_rules(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...TaxRuleFields
            }
        }
    }
    ${TAX_RULE_FIELDS},
    ${PAGINATION},
`;

export default { GET_TAX_RULE, SEARCH_TAX_RULE };