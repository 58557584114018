import { gql } from "graphql-tag";

export const TAX_RULE_FIELDS = gql`
  fragment TaxRuleFields on TaxRule {
    id
    name
    path
    countries
    tax {
      id
      name
      rate
      rate_type
    }
    created_at
  }
`;

export const PAGINATION = gql`
  fragment Pagination on TaxRulePagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { TAX_RULE_FIELDS, PAGINATION };
