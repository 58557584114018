import { gql } from "graphql-tag";
import { DIMENSION_FIELDS, PAGINATION } from "./Fragments";

export const GET_DIMENSIONS = gql`
    query GetDimension($page: Int, $limit: Int) {
        dimensions(limit:$limit, page:$page){
            ...Pagination
            data {
                ...DimensionFields
            }
        }
    }
    ${DIMENSION_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_DIMENSIONS = gql`
    query SearchDimension($page: Int, $limit: Int, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_dimensions(limit:$limit, page:$page, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...DimensionFields
            }
        }
    }
    ${DIMENSION_FIELDS},
    ${PAGINATION},
`;

export default { GET_DIMENSIONS };
