
import { defineComponent, ref, watchEffect, toRefs, onMounted } from "vue";
import { Apollo, Notify } from "@/core/services";
import { Input, Select } from "@/components/input-elements";
import { GET_ATTRIBUTES } from "../../../attributes/graphql/Queries";
import { GET_ATTRIBUTE_VALUES } from "../../../attribute-values/graphql/Queries";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import Table from "../../../../../components/Table/Table.vue";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	props: {
		locale: { type: String, default: "da" },
		variationsList: Object,
	},
	components: {
		Input,
		Select,
		Table,
		InnerLoader,
	},
	setup(props, context) {
		const { locale, variationsList } = toRefs(props);
		const attributes = ref([]) as Record<any, any>;
		const attributesList = ref([]) as Record<any, any>;
		const attributeValue = ref();
		const attributeValues = ref([]) as Record<any, any>;
		const variations: any = ref([]);
		const variation_id = ref();
		const disabled = ref(false);
		const variationImages = ref([]) as Record<any, any>;
		const inputFile = ref<null | HTMLFormElement>(null);
		const imageLabel = ref("");
		const attribute = ref("");
		const stock = ref("");
		const i18n = useI18n();
		const attributePrice = ref("");
		const attributeSku = ref("");
		const attributeProductNumber = ref("");
		const store = useStore();
		const loading = ref(false);
		const activeIndex = ref(-1);
		const variationId = ref(1);
		const columns = ref([
			{
				label: "message.NAME",
				key: "attribute_name",
			},
			{
				label: "message.VARIANT_TYPE",
				key: "attribute_value_type",
			},
			{
				label: "message.VARIANT_VALUE",
				key: "attribute_value",
				textAlignment: "center",
			},
			{
				label: "message.STOCK",
				key: "stock",
			},
			{
				label: "message.PRICE",
				key: "price",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		const getAttributes = () => {
			Apollo.watchQuery({
				query: GET_ATTRIBUTES,
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
			}).subscribe(({ data }) => {
				attributesList.value = [];
				attributesList.value = data.attributes.data;
			});
		};

		getAttributes();

		watchEffect(() => {
			variationsList?.value != undefined ? (variations.value = variationsList?.value) : null;
			// Attributes
			attributes.value = [];
			attributesList.value.forEach((element: Record<any, any>) => {
				attributes.value.push({
					label: JSON.parse(element.name)[`${locale.value}`].name,
					value: element.id,
				});
			});
		});

		// Get Attribute Value
		const getAttributeValues = (id: number, attributeId: number) => {
			Apollo.watchQuery({
				query: GET_ATTRIBUTE_VALUES,
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
				variables: {
					attribute_id: id,
				},
			}).subscribe(({ data }) => {
				attributeValue.value = "";
				attributeValues.value = [];
				data.attribute_values.data.forEach((element) => {
					attributeValues.value.push({
						label: JSON.parse(element.name)[`${locale.value}`].name,
						variant_value: element.variant_value,
						variant_type: element.variant_type,
						value: element.id,
					});
				});
				if (attributeId != 0) {
					variation_id.value != undefined ? (attributeValue.value = attributeId) : (attributeValue.value = "");
				} else {
					attributeValue.value = "";
				}
			});
		};

		// get_attribute_values(0)
		const changeAttribute = (id: number, type: string) => {
			if (type == "exitsVariation") {
				const checkExitVariation = variations.value.find((ele) => ele.attribute_value_id == id);
				if (variation_id.value != undefined) {
					if (variations.value[variation_id.value].attribute_value_id == id) {
						disabled.value = false;
					} else if (checkExitVariation) {
						disabled.value = true;
						Notify.error(i18n.t("message.VARIATION_COMBINATION_ALREADY_ADDED"));
					} else {
						disabled.value = false;
					}
				} else if (checkExitVariation) {
					disabled.value = true;
					Notify.error(i18n.t("message.VARIATION_COMBINATION_ALREADY_ADDED"));
				} else {
					disabled.value = false;
				}
			} else {
				getAttributeValues(id, 0);
			}
		};

		const triggerFile = () => {
			inputFile.value?.click();
		};

		const variationHandler = () => {
			if (stock.value != "" && attributePrice.value != "" && attribute.value != "" && attributeValue.value != "") {
				const attributeName = attributes.value.find((ele) => ele.value == attribute.value);
				const attributeValueField = attributeValues.value.find((ele) => ele.value == attributeValue.value);
				if (variation_id.value != undefined) {
					(variations.value[variation_id.value].attribute_name = attributeName.label),
						(variations.value[variation_id.value].attribute_id = attributeName.value),
						(variations.value[variation_id.value].attribute_value_id = attributeValueField.value),
						(variations.value[variation_id.value].attribute_value_name = attributeValueField.label),
						(variations.value[variation_id.value].attribute_value = attributeValueField.variant_value),
						(variations.value[variation_id.value].stock = stock.value),
						(variations.value[variation_id.value].sku = attributeSku.value),
						(variations.value[variation_id.value].price = attributePrice.value),
						(variations.value[variation_id.value].economic_number = attributeProductNumber.value);
					variations.value[variation_id.value].attribute_value_type = attributeValueField.variant_type;
					variations.value[variation_id.value].images = variationImages.value.length != 0 ? variationImages.value : variations.value[variation_id.value].images;
					variation_id.value = undefined;
				} else {
					variations.value.push({
						id: variationId.value,
						attribute_name: attributeName.label,
						attribute_id: attributeName.value,
						attribute_value_id: attributeValueField.value,
						attribute_value_name: attributeValueField.label,
						attribute_value: attributeValueField.variant_value,
						attribute_value_type: attributeValueField.variant_type,
						sku: attributeSku.value,
						economic_number: attributeProductNumber.value,
						stock: stock.value,
						price: attributePrice.value,
						images: variationImages.value,
					});
					variationId.value++;
				}
				stock.value = "";
				attributeSku.value = "";
				attributeProductNumber.value = "";
				attribute.value = "";
				attributePrice.value = "";
				attributeValue.value = "";
				imageLabel.value = "";
				variationImages.value = [];
				attributeValues.value = [];

				context.emit("productAttributeData", variations.value);
			} else {
				Notify.error(i18n.t("message.FILL_ALL_MISSING_FIELDS"));
			}
		};

		const handleEdit = (variation: Record<any, any>, index: number) => {
			getAttributeValues(variation.attribute_id, variation.attribute_value_id);
			attribute.value = variation.attribute_id;
			attributeValue.value = variation.attribute_value_id;
			stock.value = variation.stock;
			attributeProductNumber.value = variation.economic_number;
			attributeSku.value = variation.sku;
			attributePrice.value = variation.price;
			variation_id.value = index;
		};

		const variationImageHandler = async (event: Record<any, any>) => {
			variationImages.value = [];
			if (event.target.files.length == 0) {
				return false;
			}
			loading.value = true;
			const files = event.target.files[0];
			imageLabel.value = files.name;
			const media = event.target.files;
			const response = await store.dispatch(Actions.CREATE_MEDIA, media);
			const images = JSON.parse(response);
			images.forEach((element) => {
				variationImages.value.push({ src: element.asset_path, id: element.id });
			});
			loading.value = false;
		};

		const handleDelete = (id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loading.value = true;
					if (variations.value[id].images.length > 0) {
						const resp = await store.dispatch(Actions.REMOVE_MEDIA, variations.value[id].images[0].id);
						if (resp == "true") {
							variations.value.splice(id, 1);
						}
					} else {
						variations.value.splice(id, 1);
					}

					context.emit("productAttributeData", variations.value);
					loading.value = false;
				});
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		onMounted(() => {
			document.addEventListener("click", handleClickOutside);
		});

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};

		return {
			attributesList,
			variation_id,
			disabled,
			variationImages,
			imageLabel,
			inputFile,
			variations,
			attribute,
			attributes,
			attributeValues,
			attributeValue,
			stock,
			attributePrice,
			attributeProductNumber,
			attributeSku,
			columns,
			activeIndex,
			loading,
			variationId,
			handleEdit,
			handleDelete,
			changeAttribute,
			variationImageHandler,
			triggerFile,
			variationHandler,
			dropdownHandler,
			handleClickOutside,
		};
	},
});
