
import { defineComponent, ref, inject } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { UPDATE_PRODUCT_PRICE } from '../../../products/graphql/Mutations';
import { useStore } from 'vuex';
import InnerLoader from '@/components/InnerLoader.vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
    components: {
        Input,
        Select,
        InnerLoader
    },
    setup(props, context) {
        const products = ref([]) as Record<any, any>;
        const store = useStore();
        const loading = ref(false);
        const showForm = ref(false);
        const showButton = ref(true);
        const router = useRouter();
        const route = useRoute();
        const locale = ref('da');
        const actionFromRef = ref<null | HTMLFormElement>(null);
        const emitter: any = inject('emitter');
        const i18n = useI18n();
        const productUuid = String(route.params.uuid);

        const actionForm = ref({
            discount_type: '',
            discount_amount: '',
            product_id: String(route.params.uuid),
            change_type: ''
        });

        const discount_options = ref([
            {
                label: 'message.AMOUNT',
                value: 'AMOUNT'
            },
            {
                label: 'message.PERCENTAGE',
                value: 'PERCENTAGE'
            }
        ]);

        const change_options = ref([
            {
                label: 'message.INCREMENT',
                value: 'INCREMENT'
            },
            {
                label: 'message.DECREMENT',
                value: 'DECREMENT'
            }
        ]);

        const showPriceChagingForm = () => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_UPDATE_PRODUCT_PRICE')}`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    showButton.value = false;
                    showForm.value = true;
                })
                .catch(() => {
                    loading.value = false;
                });
        };

        const resetForm = () => {
            emitter.emit('clearInput');
            actionFromRef.value?.resetFields();
        };

        const changeGlobalPrice = () => {
            actionFromRef.value?.validate(valid => {
                if (valid) {
                    try {
                        loading.value = true;
                        Apollo.mutate({
                            mutation: UPDATE_PRODUCT_PRICE,
                            variables: {
                                type: actionForm.value.discount_type,
                                change_type: actionForm.value.change_type,
                                value: actionForm.value.discount_amount,
                                id: actionForm.value.product_id,
                                modal: 'product'
                            },
                            update: (store, { data: { delete_discount } }) => {
                                loading.value = false;
                                showButton.value = true;
                                showForm.value = false;
                                resetForm();
                                Notify.success(i18n.t('message.RECORD_UPDATED_SUCCESSFULLY'));
                            }
                        });
                    } catch (e) {
                        loading.value = false;
                        Notify.error(i18n.t('message.SOMETHING_WENT_WRONG'));
                        resetForm();
                    }
                }
            });
        };

        return {
            products,
            discount_options,
            actionForm,
            // changeDiscountTypeHandler,
            // validateForm,
            actionFromRef,
            resetForm,
            change_options,
            loading,
            showForm,
            showPriceChagingForm,
            changeGlobalPrice,
            showButton
        };
    }
});
