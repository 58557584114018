
import { defineComponent, ref, watchEffect, toRefs } from "vue";
import { Notify } from "@/core/services";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Image } from "@/components/input-elements";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	props: {
		mediaList: Object,
	},
	components: {
		Image,
		InnerLoader,
	},
	setup(props, context) {
		const { mediaList } = toRefs(props);

		const mediaFiles = ref([]);
		const files = ref([]);
		const previewImage = ref([]) as Record<any, any>;
		const images = ref([]) as Record<any, any>;
		const store = useStore();
		const i18n = useI18n();
		const loading = ref(false);

		watchEffect(() => {
			images.value = [];
			if (mediaList?.value) {
				mediaList?.value.forEach((element) => {
					images.value.push(element.id);
				});
				previewImage.value = mediaList?.value;
			}
		});

		// On Change Image Hanlde
		const onImageChange = async (e) => {
			mediaFiles.value = e.target.files;
			if (e.target.files.length == 0) {
				return false;
			}
			if (mediaFiles.value.length > 5) {
				Notify.error("Files limit exceed only 5 images upload");
				return;
			}
			const fileCount = previewImage.value.length + mediaFiles.value.length;
			if (fileCount > 0 && fileCount < 6) {
				files.value = [];
				for (let i = 0; i < mediaFiles.value.length; i++) {
					files.value.push(mediaFiles.value[i]);
				}
				if (files.value.length > 0) {
					loading.value = true;
					const response = await store.dispatch(Actions.CREATE_MEDIA, files.value);
					const respImages = JSON.parse(response);
					respImages.forEach((element) => {
						previewImage.value.push({ src: element.asset_path, id: element.id });
						images.value.push(element.id);
					});

					context.emit("media", images.value);
					loading.value = false;
				}
			} else {
				Notify.error(i18n.t("message.MEDIA_UPLOAD_LIMIT_MUST_NOT_GREATER_THAN_5"));
			}
		};

		// Remove Media Function
		const removeImage = (index: number, media_id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loading.value = true;
					const resp = await store.dispatch(Actions.REMOVE_MEDIA, media_id);
					if (resp == "true") {
						previewImage.value.splice(index, 1);
						images.value.splice(index, 1);
						context.emit("media", images.value);
						Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
					}
					loading.value = false;
				})
				.catch(() => {
					loading.value = false;
				})
				.catch(() => {
					loading.value = true;
					// loader.$loading().close();
				});
		};

		const showMedia = (num: number) => {
			const formData = {
				previewImage: previewImage.value,
				visible: true,
				index: num,
			};
			store.dispatch(Actions.TRIGGER_ACTION, { formData: formData, type: "imageProps" });
		};

		return {
			previewImage,
			images,
			loading,
			onImageChange,
			removeImage,
			showMedia,
		};
	},
});
