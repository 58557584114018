import { gql } from "graphql-tag";
import { ATTRIBUTE_FIELDS, PAGINATION } from "./Fragments";

export const GET_ATTRIBUTES = gql`
    query GetAttributes($page: Int, $limit: Int) {
        attributes(limit:$limit, page:$page){
            ...Pagination
            data {
                ...AttributeFields
            }
        }
    }
    ${ATTRIBUTE_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_ATTRIBUTES = gql`
    query SearchAttributes($page: Int, $limit: Int, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_attributes(limit:$limit, page:$page, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...AttributeFields
            }
        }
    }
    ${ATTRIBUTE_FIELDS},
    ${PAGINATION},
`;

export default { GET_ATTRIBUTES, SEARCH_ATTRIBUTES };