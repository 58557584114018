import { gql } from "graphql-tag";
import { PRICE_GROUP_FIELDS, PAGINATION } from "./Fragments";

export const GET_PRICE_GROUP = gql`
    query GetPriceGroups($page: Int, $limit: Int) {
        price_groups(limit:$limit, page:$page){
            ...Pagination
            data {
                ...PriceGroupFields
            }
        }
    }
    ${PRICE_GROUP_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_PRICE_GROUPS = gql`
    query SearchPriceGroups($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_price_groups(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...PriceGroupFields
            }
        }
    }
    ${PRICE_GROUP_FIELDS},
    ${PAGINATION},
`;

export default { SEARCH_PRICE_GROUPS };
