import { gql } from 'graphql-tag';
import { PRODUCT_FIELDS } from './Fragments';

export const CREATE_PRODUCT = gql`
    mutation CreateProduct($input: ProductInput!) {
        create_product(product: $input)
    }
`;

export const UPDATE_PRODUCT_PRICE = gql`
    mutation UpdateProductPrice($type: String, $value: String, $id: String, $change_type: String, $modal: String) {
        update_product_price(type: $type, value: $value, id: $id, change: $change_type, modal: $modal)
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation UpdateProduct($input: ProductInput!) {
        update_product(product: $input) {
            ...ProductFields
        }
    }
    ${PRODUCT_FIELDS}
`;

export const DELETE_PRODUCT = gql`
    mutation DeleteProduct($id: Int!) {
        delete_product(id: $id)
    }
`;

export const UPDATE_SPECIAL_PRICE = gql`
    mutation UpdateSpecialPrice($price: Float, $product_id: Int, $price_group_id: Int) {
        update_special_price(price: $price, product_id: $product_id, price_group_id: $price_group_id)
    }
`;

export default { CREATE_PRODUCT, DELETE_PRODUCT, UPDATE_PRODUCT, UPDATE_SPECIAL_PRICE, UPDATE_PRODUCT_PRICE };
