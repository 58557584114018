import { gql } from "graphql-tag";
import { ATTRIBUTE_VALUE_FIELDS, PAGINATION } from "./Fragments";

export const GET_ATTRIBUTE_VALUES = gql`
    query GetAttributesValues($page: Int, $limit: Int, $attribute_id: Int) {
        attribute_values(limit:$limit, page:$page, attribute_id:$attribute_id){
            ...Pagination
            data {
                ...AttributeValueFields
            }
        }
    }
    ${ATTRIBUTE_VALUE_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_ATTRIBUTE_VALUES = gql`
    query SearchAttributesValues($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_attribute_values(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...AttributeValueFields
            }
        }
    }
    ${ATTRIBUTE_VALUE_FIELDS},
    ${PAGINATION},
`;

export default { GET_ATTRIBUTE_VALUES, SEARCH_ATTRIBUTE_VALUES };