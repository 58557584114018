import { gql } from 'graphql-tag';

export const PRODUCT_FIELDS = gql`
    fragment ProductFields on Product {
        id
        uuid
        status
        path
        economic_number
        available_stock
        description {
            name
        }
        product_to_prices_admin {
            price
            site {
                name
            }
        }
        product_to_prices {
            id
            price
            tax
            site {
                name
            }
        }

        product_to_price_groups_admin {
            product_id
            price_group_id
            special_price
            special_price_original
        }
        media {
            id
            file_path
        }
    }
`;

export const FAVORITE_PRODUCT_FIELDS = gql`
    fragment FavoriteProductFields on FavoriteProduct {
        id
        product {
            id
            description {
                name
            }
        }
        customer {
            user_id
            user {
                name
            }
        }
    }
`;

export const FAVORITE_PRODUCT_PAGINATION = gql`
    fragment FavoriteProductPagination on FavoriteProductPagination {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
`;

export const PAGINATION = gql`
    fragment Pagination on ProductPagination {
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
`;

export default { PRODUCT_FIELDS, PAGINATION, FAVORITE_PRODUCT_FIELDS, FAVORITE_PRODUCT_PAGINATION };
