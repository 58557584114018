import { gql } from "graphql-tag";

export const MANUFACTURER_FIELDS = gql`
  fragment ManufacturerFields on Manufacturer {
    id
    name
    path
    image  {
      id
      file_path
  }
    created_at
    description
  }
`;

export const PAGINATION = gql`
  fragment Pagination on ManufacturerPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { MANUFACTURER_FIELDS, PAGINATION };