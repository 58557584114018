import { gql } from "graphql-tag";

export const ATTRIBUTE_VALUE_FIELDS = gql`
  fragment AttributeValueFields on AttributeValue {
    id
    name
    path
    variant_type
    variant_value
    attribute {
      id
      name
    }
  }
`;

export const PAGINATION = gql`
  fragment Pagination on AttributeValuePagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { ATTRIBUTE_VALUE_FIELDS, PAGINATION };