import { gql } from "graphql-tag";
import { MANUFACTURER_FIELDS, PAGINATION } from "./Fragments";

export const GET_MANUFACTURER = gql`
    query GetManufacturers($page: Int, $limit: Int) {
        manufacturers(limit:$limit, page:$page){
            ...Pagination
            data {
                ...ManufacturerFields
            }
        }
    }
    ${MANUFACTURER_FIELDS},
    ${PAGINATION},
`;

export const GET_META_TAGS_LIST = gql`
    query GetMetaTagsList {
        meta_tags_list
    }
`;

export const SEARCH_MANUFACTURER = gql`
    query SearchManufacturers($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String  , $type:String , $date_from:String , $date_to:String) {
        search_manufacturers(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale , type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...ManufacturerFields
            }
        }
    }
    ${MANUFACTURER_FIELDS},
    ${PAGINATION},
`;

export default { GET_MANUFACTURER, SEARCH_MANUFACTURER };